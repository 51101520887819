import React from "react";
import styles from "./Footer.module.css";
import fb from "../Images/fb.svg";
import insta from "../Images/instagram.png";
import {
  ADDRESS,
  EMAIL,
  NAVN,
  OPENING_HOURS,
  OPENING_HOURS_KITCHEN,
  PHONE,
} from "../helper/HelperVariables";

const Footer = () => {
  const instagramUsername = "Gustavsvinbar";

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.gustavInformation}>
          <h3 className={styles.footerTitle}>INFORMASJON:</h3>
          <p className={styles.footerText}>
            Telefon: <a href={`tel:${PHONE}`}>{PHONE}</a>
            <br />
            E-post: <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
            <span className={styles.kontakt}>
              <p>{ADDRESS}</p>
            </span>
          </p>
        </div>
        <div className={styles.followUs}>
          <h3 className={styles.footerTitle}>FØLG OSS PÅ SOSIALE MEDIER</h3>
          <p className={styles.footerText}>for oppdaterte nyheter!</p>
          <div className={styles.icons}>
            <a href="https://www.facebook.com/profile.php?id=100095026144226">
              <img src={fb} alt="Facebook" className={styles.icon} />
            </a>
            <a
              href={`https://www.instagram.com/${instagramUsername}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={insta} alt="Instagram" className={styles.icon} />
            </a>
          </div>
        </div>
        <div className={styles.openingHours}>
          <h3 className={styles.footerTitle}>ÅPNINGSTIDER:</h3>
          <p className={styles.footerText}>
            {OPENING_HOURS}
            <br />
            {OPENING_HOURS_KITCHEN}
            <br />
          </p>
        </div>
      </div>
      <div className={styles.footerCredits}>
        <div className={styles.codeCreds}>
          <div className={styles.info}>
            <p>{NAVN} | Utviklet av Elias Opstad</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
